// import Image from "next/image";
import dynamic from "next/dynamic";

const SignUpCta = dynamic(() => import("components/auth/SignUpCta/SignUpCta"), {
  ssr: false,
});
import { useInView } from "react-cool-inview";

// import HubFooter from "./HubSpotForms/HubFooter";
const HubspotFormEmbed = dynamic(() =>
  import("components/HubSpotForms/HubspotFormEmbed")
);

import styles from "./JoinUs.module.scss";

const JoinUs = ({ signIn = true, form = false }) => {
  const { observe, inView } = useInView({ unobserveOnEnter: true });

  return (
    <div className={`${styles.joinArea}`} ref={observe}>
      <div className="variable-container">
        <div className={`${styles.joinFl}`}>
          <div className={`${styles.joinRi}`}>
            {/* {form && (
              <>
                <p className={`${styles.ctaText}`}>
                  Join our newsletter to stay up to date. No spam, ever!
                </p>
                {inView && (
                  <HubspotFormEmbed
                    region="na1"
                    portalId="20685762"
                    formId="f909b3fd-a6a7-40dc-829c-3251e76febf3"
                    target="#hubspotNewsletterForm"
                    scriptSrc="//js.hsforms.net/forms/v2.js?pre=1"
                    cssClass="hubspotFormEmbed"
                  />
                )}
              </>
            )} */}
            {inView && signIn && <SignUpCta />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
